<template>
  <v-container>
    <BaseScreenHeader
      title="View Comment"
      screenInfo="PSG-002"
      width="90%"
      :showBack="false"
      :refresh="true"
      @refresh="refreshPage"
    />

    <BaseLoading :loaded="loaded" />
    <v-sheet class="charcoal">
      <v-timeline
        :key="switchNum"
        dense
        v-if="posts.length > 0"
        :class="isPhone ? 'ml-n6' : ''"
      >
        <v-timeline-item
          color="progressActive"
          fill-dot
          v-for="item in posts"
          :key="item.id"
        >
          <template v-slot:icon>
            <v-icon color="charcoal">
              mdi-comment-text-outline
            </v-icon>
          </template>
          <template v-slot:opposite>
            <span class="caption purpleAccent--text">
              {{ prettyDate(item.created_local) }}
            </span>
          </template>
          <div class="ml-n5">
            <v-card rounded flat class="pa-1 pb-0 px-2 charcoal">
              <v-card-actions
                :class="textSizeXSmall + ' py-0 px-0 purpleAccent--text'"
              >
                {{ prettyDateTime(item.created_local) }}
                <v-spacer />
                <v-icon
                  v-if="canEdit(item)"
                  color="silver"
                  @click="deleteComment(item)"
                >
                  mdi-trash-can
                </v-icon>
              </v-card-actions>
              <v-card-text class="pa-0">
                <BaseUserProfile :item="item" />
                <span v-html="item.comment" class="paper--text" />
              </v-card-text>
              <v-card-actions class="pa-0 mt-1">
                <span :class="textSize + ' silver--text ml-1'">
                  <span class="link" @click="likeComment(item)">
                    <v-icon
                      :color="item.liked == 'Yes' ? 'paper' : 'silver'"
                      large
                      class="mt-n2"
                    >
                      mdi-thumb-up-outline
                    </v-icon>
                    <span class="ml-n1"> {{ item.likes }}</span>
                  </span>
                </span>

                <span class="link" @click="replyToComment(item)">
                  <v-icon color="silver" class="ml-2 mt-n2" large>
                    mdi-comment-plus-outline
                  </v-icon>
                  <span :class="textSize + ' silver--text ml-n1'">
                    {{ item.num_replies }}
                  </span>
                </span>
                <v-spacer v-if="isPhone" />
              </v-card-actions>
              <template v-if="item.showReply && loaded">
                <v-card-text class="silver--text pa-0 ">
                  <tiptap-vuetify
                    dark
                    v-model="item.reply"
                    :extensions="extensions"
                    placeholder="Reply to comment"
                    :toolbar-attributes="{ color: 'charcoal', dark: true }"
                    :card-props="{
                      flat: true,
                      class: 'charcoal lighten-1 silver--text'
                    }"
                  />
                </v-card-text>
                <v-card-actions
                  v-if="checkComment(item.reply)"
                  class="pa-0 mr-n2"
                >
                  <v-spacer />
                  <BaseActionButton
                    color="paper"
                    icon=""
                    plain
                    label="++ Reply"
                    @clickedThis="reply(item)"
                  />
                </v-card-actions>
                <div class="mt-2">
                  <v-card
                    class="mt-1 charcoal"
                    tile
                    flat
                    v-for="(reply, index) in item.replies"
                    :key="index"
                  >
                    <v-card-actions class="pa-0">
                      <a
                        class="caption mr-1 progressActive--text"
                        :href="'/profile/' + reply.profileid"
                        :title="reply.display_name"
                      >
                        {{ reply.display_name }}
                      </a>
                      <span class="caption silver--text">
                        on {{ prettyDateTime(reply.created_local) }}
                      </span>
                      <v-spacer />
                    </v-card-actions>

                    <v-card-text
                      v-html="reply.comment"
                      class="pa-0 ml-2 charcoal silver--text"
                    >
                    </v-card-text>
                    <v-card-actions class="pa-0 mt-n4 ml-2">
                      <span @click="likeComment(reply)" class="link">
                        <v-icon
                          small
                          :color="reply.liked == 'Yes' ? 'paper' : 'silver'"
                          class="mt-n2"
                        >
                          mdi-thumb-up-outline
                        </v-icon>
                        <span :class="textSizeSmall + ' silver--text ml-n1'">
                          {{ reply.likes }}
                        </span>
                      </span>

                      <v-spacer />
                      <v-icon
                        small
                        v-if="canEdit(reply)"
                        color="silver"
                        @click="deleteReply(reply, comment)"
                      >
                        mdi-trash-can
                      </v-icon>
                    </v-card-actions>

                    <v-divider class="charcoal lighten-1 mt-2" />
                  </v-card>
                </div>
              </template>
            </v-card>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-sheet>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import debounce from 'lodash/debounce'

import {
  TiptapVuetify,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak
} from 'tiptap-vuetify'

export default {
  components: { TiptapVuetify },
  name: 'ViewComment',
  mixins: [validationMixin, util],
  data() {
    return {
      switchNum: 0,
      loaded: false,
      isActive: false,
      showWorkoutDialog: false,
      showDeleteDialog: false,
      showStats: false,
      showInvitesDialog: false,
      showExerciseInfo: false,
      invites: [],
      volumeStats: {},
      isUserScrolling: false,
      isNow: true,
      selectedWorkout: null,
      exerciseid: null,
      metric: 0,
      imperial: 0,
      flips: 0,
      search: '',
      selectedMeasure: [],
      measureList: [],
      posts: [],
      news: [],
      profile: null,
      datetime: null,
      debouncedScroll: null,
      debounce: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100, //fetch from API
      bottom: false,
      reload: false,
      profileLoaded: false,
      extensions: [
        Paragraph,
        Bold,
        Italic,
        Link,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak
      ]
    }
  },
  props: {
    itemid: {
      type: String,
      default: null
    },
    itemType: {
      type: String,
      default: 'event'
    }
  },
  beforeMount() {
    this.loadPage()
  },

  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },

  watch: {
    bottom(bottom) {
      if (bottom) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.posts.length
    },

    ...appConfig
  },
  methods: {
    cancelInfo() {
      this.exerciseid = null
      this.showExerciseInfo = false
    },
    checkComment(newComment) {
      if (!newComment) return false
      let noHTML = newComment.replace(/<[^>]*>?/gm, '')
      return noHTML.length >= 2
    },
    refreshPage() {
      this.loaded = false
      this.currentPage = 0
      this.reload = true
      this.loadPage()
    },
    replyToComment(comment) {
      comment.showReply = !comment.showReply
    },
    showComments(item, type) {
      item.showComments = !item.showComments
      if (item.showComments) this.loadComments(item, type)
    },
    hideComments(item) {
      item.showComments = false
    },
    showInvites() {
      this.showInvitesDialog = true
    },
    cancelInvites() {
      this.showInvitesDialog = false
    },
    loadComments(item, type) {
      let pagination = '/' + item.currentPage + '/' + item.pagesize
      return axios
        .get(
          this.baseURL + '/comments/' + type + '/' + item.id + pagination,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            response.data.data.forEach(element => {
              element.showReply = false
              element.reply = ''
              element.replies = JSON.parse(element.replies)
            })
            item.loadedComments = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    setResults(value) {
      this.posts = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    showWorkout(value) {
      this.selectedWorkout = value.data.workoutid
      this.showWorkoutDialog = true
    },
    showProfile(profileid) {
      this.$router.push('/profile/' + profileid)
    },
    cancel() {
      this.selectedWorkout = null
      this.showWorkoutDialog = false
    },
    previewProfile(item) {
      this.profileLoaded = false
      return axios
        .get(this.baseURL + '/user/public/profile/' + item.profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.profile = response.data.data
            this.profileLoaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    likeComment(comment) {
      if (comment.liked != 'Yes') {
        return axios
          .post(this.baseURL + '/comment/like', {
            commentid: comment.id,
            now: this.now()
          })
          .then(response => {
            if (response.status == 200) {
              comment.likes++
              comment.liked = 'Yes'
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      } else {
        return axios
          .post(this.baseURL + '/comment/unlike', { commentid: comment.id })
          .then(response => {
            if (response.status == 200) {
              comment.likes--
              comment.liked = 'No'
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },

    reply(comment) {
      return axios
        .post(this.baseURL + '/comment/reply', {
          comment: comment,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            let reply = JSON.parse(response.data.data)
            if (comment.replies != null) comment.replies.unshift(reply)
            else {
              comment.replies = []
              comment.replies[0] = reply
            }
            this.toast('Reply Added!')
            comment.reply = ''
            comment.num_replies++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteComment(comment) {
      return axios
        .delete(this.baseURL + '/comment/' + comment.id)
        .then(response => {
          if (response.status == 200) {
            this.$router.push('/')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteReply(reply, comment) {
      return axios
        .delete(this.baseURL + '/comment/reply/' + reply.id)
        .then(response => {
          if (response.status == 200) {
            comment.num_replies--
            comment.replies = comment.replies.filter(el => {
              return el.id != reply.id
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteCommentOld(item, comment) {
      return axios
        .delete(this.baseURL + '/comment/event/' + comment.id + '/' + item.id)
        .then(response => {
          if (response.status == 200) {
            item.loadedComments = item.loadedComments.filter(el => {
              return el.id != comment.id
            })
            item.comments--
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadPage() {
      return axios
        .get(this.baseURL + '/post/comment/' + this.itemid, {})
        .then(response => {
          if (response.status == 200) {
            let responseData = response.data.data

            responseData.forEach(element => {
              element.replies = JSON.parse(element.replies)
              element.showReply = false
              element.pagesize = this.pagesize
              element.currentPage = 0
              element.newReply = ''
              element.preview_profile = false
            })
            this.posts = responseData

            this.loaded = true
            this.reload = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
